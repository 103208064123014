import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { providersData } from "../../../../lib/data/searchGamesData";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import { Container, Dropdown, Form } from "react-bootstrap";

import CasinoBanner from "../../../../assets/images/banner/casino-banner.jpg";
import RouletteCat from "../../../../assets/images/categories/Roulette.png";
import AndarBaharCat from "../../../../assets/images/categories/AndarBahar.png";
import BaccaratCat from "../../../../assets/images/categories/Baccarat.png";
import BlackjackCat from "../../../../assets/images/categories/Blackjack.png";
import DragonTigerCat from "../../../../assets/images/categories/DragonTiger.png";
import PokerCat from "../../../../assets/images/categories/Poker.png";
import SpribeCat from "../../../../assets/images/categories/Spribe.png";
import SupernowaCat from "../../../../assets/images/categories/Supernowa.png";
import VivoCat from "../../../../assets/images/categories/Vivo.png";
import TVShowsCat from "../../../../assets/images/categories/TVShow.png";
import AllgamesCat from "../../../../assets/images/categories/AllGame.png";
import SicboCat from "../../../../assets/images/categories/Sicbo.png";
import Cards32Cat from "../../../../assets/images/categories/Cards32.png";
import Lucky7Cat from "../../../../assets/images/categories/Lucky7.png";
import SearchIcon from "../../../../assets/images/icons/icon-search.png";
import SpeedRoulette from "../../../../assets/images/roulette/home/speed-roulette1.jpg";
import play from "../../../../assets/images/play-button.png";
import { casinoSection } from "../../../../lib/data/casinoSection.data";
import { tab } from "@testing-library/user-event/dist/tab";
import Header from "../../../../containers/Header";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { APP_CONST } from "../../../../config/const";
import ModalWrapper from "../../../../containers/ModalWrapper";
import BottomNav from "../../../../containers/BottomNav";

const AllCasino = () => {
  const { isAuth } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "All Games",
    key: "popularGames",
    imgSrc: AllgamesCat,
  });
  const totalGame = {
    ...casinoSection?.AndarBahar,
    ...casinoSection?.baccarattab,
    ...casinoSection?.blackjacktab,
    ...casinoSection?.dragontiger,
    ...casinoSection?.pokertab,
    ...casinoSection?.popularGames,
    ...casinoSection?.roulettetab,
    ...casinoSection?.spribe,
    ...casinoSection?.supernowa,
    ...casinoSection?.tvShows,
    ...casinoSection?.vivo,
  };
  const [isTrue, setIsTrue] = useState(false);
  const [showFilters, setshowFilters] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSearched, setIsSearched] = useState(false);

  const [query, setQuery] = useState("");
  const handleSearch = () => {
    // Perform the search operation here
    console.log("Searching for:", query);
    let filteredValue = Object.values(totalGame)?.filter((item) => {
      return item.name.toLowerCase().indexOf(query?.toLowerCase()) > -1;
    });
    setSearchData(filteredValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsSearched(true);
      handleSearch();
    }
  };

  // console.log(isFavourite);
  const checkIsFav = (item) => {
    if (item?.isFav) {
      setIsFavourite(false);
    } else {
      setIsFavourite(true);
    }
  };

  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);
  const providersName = ["Ezugi", "Vivo", "Spribe", "Supernowa"];
  const [checkedItems, setCheckedItems] = useState({});
  const gameType = [
    "roulette",
    "poker",
    "baccarat",
    "blackjack",
    // "first person",
    // "sicbo",
    "andar bahar",
  ];
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedGameTypeValues, setSelectedGameTypeValues] = useState([]);
  console.log("selectedGameTypeValues", selectedGameTypeValues);

  console.log("selectedValues", selectedValues);

  const dataNew = Object.values(searchData || {}).filter((casino) =>
    selectedValues.length > 0
      ? selectedValues.includes(casino?.providerName?.toLowerCase())
      : casino?.providerName !== "Evolution"
  );
  const filteredCasinos = Object.values(totalGame || {}).filter((casino) =>
    selectedValues.length > 0
      ? selectedValues.includes(casino?.providerName?.toLowerCase())
      : casino?.providerName !== "Evolution"
  );
  console.log("filteredCasinos", filteredCasinos);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedValues((prevSelectedValues) => {
      if (checked) {
        // Add value to the array if it is checked
        return [...prevSelectedValues, value];
      } else {
        // Remove value from the array if it is unchecked
        return prevSelectedValues.filter((val) => val !== value);
      }
    });
    setCheckedItems((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };
  const handleCheckboxChangeGameType = (event) => {
    const { value, checked } = event.target;

    setSelectedGameTypeValues((prevSelectedValues) => {
      if (checked) {
        // Add value to the array if it is checked
        return [...prevSelectedValues, value];
      } else {
        // Remove value from the array if it is unchecked
        return prevSelectedValues.filter((val) => val !== value);
      }
    });
  };
  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    { id: 3, name: "Ezugi", eventKey: "ezugi" },
    { id: 4, name: "Vivo", eventKey: "vivo" },
  ];
  const tabsArray = [
    {
      id: 1,
      name: "Popular Games",
      key: "popularGames",
      imgSrc: AllgamesCat,
    },
    {
      id: 2,
      name: "Andar Bahar",
      key: "AndarBahar",
      imgSrc: TVShowsCat,
    },
    {
      id: 3,
      name: "Roulette",
      key: "roulettetab",
      imgSrc: RouletteCat,
    },
    {
      id: 4,
      name: "Dragon Tiger",
      key: "dragontiger",
      imgSrc: RouletteCat,
    },
    {
      id: 5,
      name: "Baccarat",
      key: "baccarattab",
      imgSrc: BaccaratCat,
    },
    {
      id: 6,
      name: "Tv Shows",
      key: "tvShows",
      imgSrc: TVShowsCat,
    },
    {
      id: 7,
      name: "Blackjack",
      key: "blackjacktab",
      imgSrc: BlackjackCat,
    },
    {
      id: 8,
      name: "Poker",
      key: "pokertab",
      imgSrc: PokerCat,
    },
    {
      id: 9,
      name: "Spribe",
      key: "spribe",
      imgSrc: SpribeCat,
    },
    {
      id: 10,
      name: "Supernowa",
      key: "supernowa",
      imgSrc: SupernowaCat,
    },
    {
      id: 11,
      name: "Vivo",
      key: "vivo",
      imgSrc: VivoCat,
    },
  ];
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    // let filteredTabs = [];
    // for (let key in casinoSection) {
    //   Object.values(casinoSection[key]).map((values) => {
    //     if (
    //       typeof values !== "string" &&
    //       values?.providerName?.toLowerCase() === provider.eventKey
    //     ) {
    //       if (!filteredTabs.some((e) => e === key)) {
    //         filteredTabs.push(key);
    //       }
    //     }
    //   });
    // }
    // let tabToBeActive = "";
    // for (let i = 0; i < filteredTabs?.length; i++) {
    //   const filterValue = tabsArray.find((f) => f.key === filteredTabs[i]);
    //   if (filterValue) {
    //     tabToBeActive = filterValue;
    //     break;
    //   }
    // }
    // setCustomActiveTab(tabToBeActive);
    setCustomActiveTab({
      id: 1,
      name: "All Games",
      key: "allGames",
      imgSrc: RouletteCat,
    });
    filterAllGamesData(provider.eventKey);
  };

  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    console.log("provider", provider);
    let emptyArray = {};
    Object.keys(casinoSection).map((key) => {
      // console.log("key", key);
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoSection[key] !== "string"
      ) {
        emptyArray = { ...emptyArray, ...casinoSection[key] };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo"
      );
    }
    console.log("filteredValues", filteredValues);
    setAllGamesData(filteredValues);
  };

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className=" main">
        <div className="casino-games">
          <div className="casino-banner d-none d-lg-block">
            <div className="img-box">
              <img src={CasinoBanner} alt="casino banner" />
            </div>
          </div>

          <div className="filter-box">
            <div className="custom-container">
              <div className="inner-box d-none d-lg-flex">
                <div className="searchbar">
                  <img src={SearchIcon} alt="search icon" />
                  <Form.Control
                    id="gameSearch"
                    type="text"
                    placeholder="Search Games"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <ul className="sort-games">
                  <li>Sort：</li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      className={!isTrue ? "btn-sort active" : "btn-sort"}
                      onClick={() => setIsTrue((prev) => !prev)}
                    >
                      Recommend
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      className={!isTrue ? "btn-sort" : "btn-sort active"}
                      onClick={() => setIsTrue((prev) => !prev)}
                    >
                      A-Z
                    </a>
                  </li>
                </ul>
              </div>

              <div className="inner-box mobile d-lg-none">
                <h3>Casino</h3>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Filter
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      className={!isTrue ? "btn-sort active" : "btn-sort"}
                      onClick={() => setIsTrue((prev) => !prev)}
                    >
                      Recommend
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      className={!isTrue ? "btn-sort" : "btn-sort active"}
                      onClick={() => setIsTrue((prev) => !prev)}
                    >
                      A-Z
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="more-filter">
            <div className="custom-container">
              <div className="all-filters ">
                <div className="providers">
                  {showFilters && (
                    <div className="filter-heading">
                      <p>Provider</p>
                    </div>
                  )}

                  <div className="filters d-flex justify-content-between">
                    <Form>
                      {providersName.map((item, id) => {
                        return (
                          <Form.Check id={`check-api-${"checkbox"}-${item}`}>
                            <Form.Check.Input
                              value={item.toLowerCase()}
                              onChange={handleCheckboxChange}
                              checked={selectedValues.includes(
                                item.toLowerCase()
                              )}
                              type={"checkbox"}
                              isValid
                            />
                            <Form.Check.Label>{item}</Form.Check.Label>
                          </Form.Check>
                        );
                      })}
                    </Form>
                    {!showFilters && (
                      <div className="more-btn">
                        <button
                          type="button"
                          onClick={() => setshowFilters(true)}
                          className="btn more-btn"
                        >
                          More filters
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/* <ul className="categories">
                  {tabsArray?.map((tab, index) => {
                    return (
                      <React.Fragment key={index}>
                        {((casinoSection?.[tab?.key] &&
                          Object.values(casinoSection?.[tab?.key]).some(
                            (s) =>
                              s?.providerName?.toLowerCase() ===
                              selectedProvider?.eventKey
                          )) ||
                          selectedProvider?.eventKey === "all" ||
                          tab?.key == "GamesAll") && (
                          <li
                            onClick={() => {
                              setCustomActiveTab(null);
                              if (tab.key === "allGames") {
                                filterAllGamesData();
                              }
                              setActiveTab(tab);
                            }}
                            // key={index}
                          >
                            <a
                              // href="javascript:void(0)"
                              className={getTabActiveClass(tab) ? "active" : ""}
                            >
                              <img src={tab?.imgSrc} alt="roulette" />
                              <span>{tab?.name}</span>
                            </a>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul> */}

                {showFilters && (
                  <div className="all-categories">
                    <div className="filter-heading">
                      <p>Game Type</p>
                    </div>
                    <div className="filters">
                      <Form>
                        {gameType.map((item, id) => {
                          return (
                            <Form.Check
                              type={"checkbox"}
                              id={`check-api-${"checkbox"}-${item}`}
                            >
                              <Form.Check.Input
                                type={"checkbox"}
                                value={item.toLowerCase()}
                                checked={selectedGameTypeValues.includes(
                                  item.toLowerCase()
                                )}
                                onChange={handleCheckboxChangeGameType}
                                isValid
                              />
                              <Form.Check.Label>{item}</Form.Check.Label>
                            </Form.Check>
                          );
                        })}
                      </Form>
                    </div>
                  </div>
                )}

                {/* <Dropdown
                  onSelect={(e) => {
                    const provider =
                      providersArray.find((p) => p?.eventKey === e) || "all";
                    setSelectedProvider(provider);
                    manageActiveTab(provider);
                  }}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="provider-dropdown"
                    value={selectedProvider}
                  >
                    {selectedProvider?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu value={selectedProvider}>
                    {providersArray.map((p, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={p?.eventKey}>
                          {p?.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown> */}

                {showFilters && (
                  <div className="hide-items">
                    <button
                      type="button"
                      onClick={() => setshowFilters(false)}
                      className="btn hide-btn"
                    >
                      Hide item
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isSearched ? (
            <div className="custom-container">
              <div className="all-images">
                <ul>
                  {filteredCasinos
                    .filter((item, id) =>
                      selectedGameTypeValues.length > 0
                        ? selectedGameTypeValues.includes(
                            item?.gameType?.toLowerCase()
                          )
                        : item
                    )
                    .sort((a, b) => isTrue && a?.name.localeCompare(b?.name))
                    .map((casino, index) => {
                      return (
                        <React.Fragment key={index}>
                          {
                            <li key={index}>
                              {/* <div
                              onClick={() => checkIsFav(casino)}
                              className={
                                casino?.isFav == isFavourite
                                  ? "bg-primary"
                                  : "bg-secondary"
                              }
                            >
                              Fav
                            </div> */}
                              <a
                                onClick={() => {
                                  if (isAuth) {
                                    window.location.href = casino?.redirectUrl;
                                  } else {
                                    !isMobile
                                      ? handleModalShow(
                                          APP_CONST.AUTH_MODAL.LOGIN
                                        )
                                      : navigate("/sign-in");
                                  }
                                }}
                                // href={casino?.redirectUrl}
                              >
                                <img src={casino?.imgUrl} />
                                <img src={play} className="play_button" />
                                {/* <p>{casino?.name}</p> */}
                              </a>
                              <div class="game-inner">
                                <div class="game-group">
                                  {/* <h4>{casino?.name}</h4> */}
                                </div>
                              </div>
                              <div className="overlay">
                                <div className="play-btn">
                                  <a
                                    onClick={() => {
                                      if (isAuth) {
                                        window.location.href =
                                          casino?.redirectUrl;
                                      } else {
                                        !isMobile
                                          ? handleModalShow(
                                              APP_CONST.AUTH_MODAL.LOGIN
                                            )
                                          : navigate("/sign-in");
                                      }
                                    }}
                                  >
                                    Play Game
                                  </a>
                                </div>
                              </div>
                            </li>
                          }
                        </React.Fragment>
                      );
                    })}
                </ul>
              </div>
            </div>
          ) : (
            <div className="custom-container">
              <div className="all-images">
                <ul>
                  {Object.values(dataNew) &&
                    dataNew
                      .filter((item, id) =>
                        selectedGameTypeValues.length > 0
                          ? selectedGameTypeValues.includes(
                              item?.gameType?.toLowerCase()
                            )
                          : item
                      )
                      .sort((a, b) => isTrue && a?.name.localeCompare(b?.name))
                      .map((casino, index) => {
                        return (
                          <React.Fragment key={index}>
                            {
                              <li key={index}>
                                {/* <div
                              onClick={() => checkIsFav(casino)}
                              className={
                                casino?.isFav == isFavourite
                                  ? "bg-primary"
                                  : "bg-secondary"
                              }
                            >
                              Fav
                            </div> */}
                                <a href={casino?.redirectUrl}>
                                  <img src={casino?.imgUrl} />
                                  <img src={play} className="play_button" />
                                  <p>{casino?.name}</p>
                                  <p className="hover_Name">{casino?.name}</p>
                                </a>
                                <span className="focus-border">
                                  <i></i>
                                </span>
                              </li>
                            }
                          </React.Fragment>
                        );
                      })}
                </ul>
              </div>
            </div>
          )}
        </div>
        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </main>

      <BottomNav />
    </>
  );
};

export default AllCasino;
