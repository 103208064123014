import React from "react";
import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";

import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Facebook from "../../../../assets/images/facebook-512.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import { useNavigate } from "react-router-dom";

const ContactUsBefore = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>

      <main className="main">
        <div className="contact_sec Contact">
          <Container>
            <div className="pages_top_head">
              <h5>Contact Us</h5>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back
              </div>
            </div>

            <Row className="align-items-center justify-content-center">
              <Col lg={10} xl={8}>
                <div className="contact_info">
                  <Row className="align-items-center">
                    <Col md={6}>
                      <ul>
                        <li>
                          <a
                            className="panel"
                            href={
                              appDetails?.WHATS_APP_URL != ""
                                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                                : "#"
                            }
                            target={
                              appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                            }
                          >
                            <img src={Whatsapp} alt="Whatsapp Icon" />
                            <p>Whatsapp</p>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              appDetails?.TELEGRAM != ""
                                ? appDetails?.TELEGRAM
                                : "#"
                            }
                            target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                          >
                            <img src={Telegram} alt="Telegram Icon" />
                            <p>Telegram</p>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              appDetails?.INSTATGRAM != ""
                                ? appDetails?.INSTATGRAM
                                : "#"
                            }
                            target={
                              appDetails?.INSTATGRAM != "" ? "_blank" : ""
                            }
                          >
                            <img src={Instagram} alt="Instagram Icon" />
                            <p>Instagram</p>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              appDetails?.FACEBOOK != ""
                                ? appDetails?.FACEBOOK
                                : "#"
                            }
                            target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                          >
                            <img src={Facebook} alt="Facebook Icon" />
                            <p>Facebook</p>
                          </a>
                        </li>
                      </ul>
                    </Col>
                    <Col
                      md={6}
                      className="d-none d-md-flex justify-content-center"
                    >
                      <div className="contact_img">
                        <img src={ContactImg} alt="Contact Image" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </>
  );
};

export default ContactUsBefore;
